import React, { useState, useEffect } from 'react';
// import './MarketMovement.css';

const apiKeyPoligonScan = process.env.REACT_APP_API_POLYGONSCAN;
const apiKeyAlchemy = process.env.REACT_APP_API_ALCHEMY;
const nftContractAddress = process.env.REACT_APP_CONTRACT_ADDRESS_TRIPLES;

const MarketMovement = () => {
  const [movements, setMovements] = useState([]);
  const fetchMovements = async () => {
    const response = await fetch(`https://api.polygonscan.com/api?module=account&action=tokennfttx&contractaddress=${nftContractAddress}&startblock=0&endblock=99999999&page=1&offset=12&sort=desc&apikey=${apiKeyPoligonScan}`);
    const data = await response.json();
    setMovements(data.result);
  };

  useEffect(() => {
    fetchMovements();
  }, []);

  return (
    <div className="market-movement container mb-4">
      <h2>Market Movements</h2>
      <ul className="list-group">
        {movements.map((movement, index) => (
          <li key={index} className="list-group-item  bg-dark text-white">
            { movement.from === '0x0000000000000000000000000000000000000000' ? <span className="badge bg-info text-dark">Mint</span> : <span className="badge bg-success">Transfer</span> }&nbsp;
            { movement.from} To {movement.to}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MarketMovement;