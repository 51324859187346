// Contact.js
import React from 'react';
// import './Contact.css';

const Contact = () => {
  return (
    <div className="contact container">
      <h2>Contact Us</h2>
      <p>This is the Contact page content.</p>
    </div>
  );
};

export default Contact;