import React, { useRef } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Banner from './components/Banner';
import NFTCollection from './components/NFTCollection';
import MarketMovement from './components/MarketMovement';
import About from './components/About';
import Contact from './components/Contact';
import LoadingBar from 'react-top-loading-bar';
import './App.css';



function App() {
  const loadingBarRef = useRef(null);


  const startLoading = () => {
    loadingBarRef.current.continuousStart();
  };
  const stopLoading = () => {
    loadingBarRef.current.complete();
  };

  return (
    <div className="App">
      <LoadingBar color="#f11946" ref={loadingBarRef} />
      <Header />
      <Banner />
      <Routes>
        <Route path="/" element={<NFTCollection startLoading={startLoading} stopLoading={stopLoading} />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <MarketMovement />
      <Footer />
    </div>
  );
}

export default App;