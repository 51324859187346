// About.js
import React from 'react';
// import './About.css';

const About = () => {
  return (
    <div className="about container">
      <h2>About Us</h2>
      <p>This is the About page content.</p>
    </div>
  );
};

export default About;