import React, { useState, useEffect, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';

// import './NFTCollection.css';

const apiKeyPoligonScan = process.env.REACT_APP_API_POLYGONSCAN;
const apiKeyAlchemy = process.env.REACT_APP_API_ALCHEMY;
const nftContractAddress = process.env.REACT_APP_CONTRACT_ADDRESS_TRIPLES;

const NFTCollection = ({ startLoading, stopLoading }) => {
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(true);


  const fetchNFTs = useCallback(async () => {
    startLoading();
    setLoading(true);

    let response = await fetch(`https://api.polygonscan.com/api?module=account&action=tokennfttx&contractaddress=${nftContractAddress}&startblock=0&endblock=99999999&page=1&offset=12&sort=desc&apikey=${apiKeyPoligonScan}`);
    let data = await response.json();

    let subResponse = data.result.map(async (row) => {
      let tokenID = row.tokenID;
      let responseB = await fetch(`https://polygon-mainnet.g.alchemy.com/nft/v3/${apiKeyAlchemy}/getNFTMetadata?contractAddress=${nftContractAddress}&tokenId=${tokenID}&refreshCache=true`);
      let meta = await responseB.json();
      return meta;
    });

    let allNewData = await Promise.all(subResponse);
    setNfts([]);
    setNfts(allNewData);
    setLoading(false);

    stopLoading();
  }, [startLoading, stopLoading]);

  useEffect(() => {
    fetchNFTs();
    const interval = setInterval(fetchNFTs, 15000);
    return () => clearInterval(interval);
  }, [fetchNFTs]);

  // function displayNFTImage(nftData) {
  //   if (nftData  && nftData.raw.metadata.objekt.thumbnailImage && nftData.raw.metadata.objekt.thumbnailImage != null)
  //     return nftData.raw.metadata.objekt.thumbnailImage.toString().replace('/4x', '/1x');
  //   else
  //     return '';
  // }

  const NFTImage = ({ src, alt }) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
      rootMargin: '100px',
    });
    return (
      <div ref={ref} style={{ minHeight: '200px' }}>
        {inView && <img src={src} alt={alt} className="card-img-top" />}
      </div>
    );
  };

  return (
    <>
      <div className="nft-collection container">
        {/* {loading ? (
        <div className="skeleton-loader">Loading...</div>
      ) : ( */}
        <div className="row">
          {nfts.map((nft, index) => (
            <div key={index} className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
              <div className="card nft-card mb-4 bg-dark">
                <div className="text-white">tokenId = {nft.tokenId}</div>
                {/* <img src={displayNFTImage(nft)} className="card-img-top" alt={nft.name} loading="lazy" /> */}
                <NFTImage key={index} src={nft.raw.metadata.objekt.thumbnailImage} alt={nft.name} />
                <div className="card-body bg-primary text-white">
                  <h5 className="card-title">{nft.name}</h5>
                  <p className="card-text">{nft.raw.metadata.image}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* )} */}
      </div>
    </>
  );
};

export default NFTCollection;