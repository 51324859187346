import React from 'react';
// import './Banner.css';

const Banner = () => {
  return (
    <div className="banner text-center text-white">
      <h1>Welcome to the NFT Collection</h1>
    </div>
  );
};

export default Banner;